.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.LandingpageC {
  position: absolute;
  left: 18%;
  top: 2%;
  width: 20%;
  z-index: -1;
}

.LandingpageN {
  position: absolute;
  right: 24%;
  bottom: 2%;
  width: 20%;
  z-index: -1;
}

@media only screen and (max-width: 1267px) {
  .LandingpageC{
    visibility: hidden;
  }

  .LandingpageN{
    visibility: hidden;
  }
}

.mdfile {
  font-size: 10px;
  margin-top: 15px;
  height: 150px;
  overflow: auto;

}

code {
  font-family: 'Inter', sans-serif;
  font-size: 10px !important;
}

.termsandConditionsText{
  color: gray;
  font-size: 9px;
  margin: auto;
}

.footerText{
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}