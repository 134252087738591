* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: sans-serif;
  background: #262626;  /* fallback for old browsers */
  background-image: url("Assets/landingpage.jpg");
  /* background-position: right; */
  background-repeat: no-repeat;
  background-size: cover;
}

body, html, .App, #root, .outer {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.btn-dark{
  background-color: #000!important;
  border-color: #000 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 4px !important;
}

.inner {
  width: 400px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 24px 32px 0px 32px;
  border-radius: 4px;
  transition: all .3s;
}

.rectangle {
  width: 32px;
  height: 8px;
  background-color: #FFBB00;
  margin-bottom: 32px;
}

.outer .form-control{
  border-color: #595959;
  border-radius: 4px;
  margin-bottom: 6px;
}

.semi-text{
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #000000;
}

.semi-label{
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000000;
  margin-top: -16px;
  margin-bottom: -16px;
}

.outer .form-control:focus {
  border-color: #ff6918ff;
  box-shadow: none;
}

.form-group{
  font-size: 13px;
  font-weight: 500;
}

.outer h3 {
  text-align:start;
  margin: 0;
  line-height: 1;
  padding-bottom: 16px;
  font-weight:700;
  font-size: 24px;
  letter-spacing: -0.24px;
  color: #000000;
}

.outer h6 {
  text-align:start;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  font-weight:700;
  font-size: 12px;
  letter-spacing: -0.12px;
  text-transform: uppercase;
  color: #262626;
}

.error{
  margin-top: 6px;
  margin-bottom: 18px;
  vertical-align: middle;
}

.error-box{
  position:fixed;
  width: 2px;
  height: 16px;
  background-color: #DB0657;
  margin-top: 1px;
  margin-bottom: 1px;
}

.error-text {
  text-align: left;
  font-size: 12px;
  font-weight: 900;
  color: #262626;
  background-color: #FFF4F8; 
  padding-left: 6px;
}

.navbar-brand{
  width: 168.04px;
}

.back-text{
  margin-top: 23px;
  font-weight: 700;
  font-size: 14px;
  color: #262626;
  cursor: pointer;
  margin-bottom: -2px;
}

.back-text:hover{
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .inner {
    width: 400px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 24px 32px 0px 32px;
    border-radius: 4px;
    transition: all .3s;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1300px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1500px !important;
  }
}
@media only screen and (min-width: 1800px) {
  .container {
    max-width: 1700px !important;
  }
}
@media only screen and (min-width: 2000px) {
  .container {
    max-width: 1900px !important;
  }
}
@media only screen and (min-width: 2200px) {
  .container {
    max-width: 2100px !important;
  }
}

.footer {
  width: 400px;
  height: auto;
  background-color: #FAFAFA;
  margin-left: -32px;
  border-bottom-left-radius: 4px;
  margin-right: -32px;
  border-bottom-right-radius: 4px;
  margin-top: 20px;
  text-align: center;
  vertical-align: middle;
  padding: 12px 0;
}

.help-text{
  font-weight: 400 !important;
  font-size: 14px !important;
  /* color: #000000; */
  margin-bottom: 2px;
}

.help-email-text{
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000;
  margin-bottom: 2px;
}

.help-text:hover{
  text-decoration: underline;
}
.help-icon{
  width: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
}

.register-text{
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000;
  margin-bottom: 2px;
  text-align: center !important;
}

.register-div{
  text-align: center;
}